import React from 'react';
import companyGeneralDataReducer from '../../redux/companyGeneralData';
import withReducers from '../../store/withReducers';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import moment from 'moment';
import { UsersIcon } from '@commonsku/styles';

function AdminBillingRenewalSummary({ title }) {
    const [ { data } ] = useCompanyGeneralData();
    const onContract = !!data.contracted_year;

    let summary: string;

    if (onContract) {
        const today: number = new Date().setHours(0, 0, 0, 0);
        const pastMigrationDueDate: boolean = !data.migration_due_date ? true : today >= Date.parse(`${data.migration_due_date} 00:00:00`);
        const nextRenewal = moment(pastMigrationDueDate ? data.renewal_date : data.migration_due_date).format("MMM [1st] YYYY")
        summary = `Your contract will ${pastMigrationDueDate ? 'automatically renew' : 'start'} on ${nextRenewal}`;
    } else {
        summary = 'Upcoming changes to your commonsku account';
    }   

    return (
        <div style={{ display: 'flex' }}>
            <div><UsersIcon size='large' mr={16} color='#DAE9EE' /></div>
            <div>
                <h5>{title}</h5>
                <p className='tile-summary-content'>{summary}</p>
            </div>
        </div>
    );
}

export default withReducers(AdminBillingRenewalSummary, {
    companyGeneralData: companyGeneralDataReducer,
});
